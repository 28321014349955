@import '../../global.scss';

.portfolio{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        font-size: 50px;
        @include mobile{
            font-size: 30px;
            }
    }
    ul{
        margin: 30px;
        padding: 0;
        list-style: none;
        display: flex;
        @include mobile{
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px 0;
            }
        li{
            font-size: 16px;
            margin-right: 50px;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;
            &.active{
                background-color:$mainColor;
                color: #fff;
            }
        }
    }

    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @include mobile{
            width: 100%;;
            }
        .item{
            width: 250px;
            height: 150px;
            border-radius: 10px;
            border: 1px solid #eee;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            position: relative;
            transition: 0.5s all ease;
            cursor: pointer;

            @include mobile{
                width: 150px;
                height: 120px;
                }

            a{
                position: absolute;
                font-size: 16px;
                color:#fff;
                text-decoration: none;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }
            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.1;
                    z-index: 0;
                }

            }
        }
    }
}