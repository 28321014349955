@import '../../global.scss';

.intro{
    background-color: #fff;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .left{
        flex: 0.5;
        overflow: hidden;
        .imgContainer{
            width: 900px;
            height: 900px;
            background-color: #FF7F50;
            border-radius: 70%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;
            @include mobile{
                align-items: flex-start;
            }


            img{
                height: 90%;

                @include mobile{
                    height: 50%;
                }
            }
        }
    }
    .right{
        flex: 0.5;
        position: relative;
        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include mobile{
                padding-left: 0;
                align-items: center;
            }

            h1{
                font-size: 60px;
                margin: 20px 0;

                @include mobile{
                    font-size: 40px;
                }
            }
            h2{font-size: 30px;
                @include mobile{
                font-size: 40px;
                }
            }
            h3{font-size: 30px;
                @include mobile{
                    font-size: 20px;
                    }
                span{
                    color: #FF7F50;
                }
                .ityped-cursor{
                    animation: blink 1s infinite;
                    @keyframes blink {
                        0%{
                            opacity: 0;
                        }
                        50%{
                            opacity: 1;
                        }
                        100%{
                            opacity: 0;
                        }
                    }
                }
            }
            
            
        }
        a{
            position: absolute;
            bottom: 10px;
            @include mobile{
                left: 50%;
                }
            img{
                animation: arrowBlink 2s infinite;

            }
            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }
        }
    }
}