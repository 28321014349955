@import '../../global.scss';

.contact{
    background-color: #fff;
    display: flex;
    @include mobile{
        flex-direction: column;
      }
    .left{
        flex: 1;
        overflow: hidden;
        img{
            height: 100%;
        }

    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2{
            font-size: 30px;

        }

        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            input{
                width: 300px;
                height: 30px;
                font-size: 14px;
                margin-bottom: 20px;
                padding: 0 10px;

                @include mobile{
                    width: 200px;
                    height: 20px;
                  }
            }
            textarea{
                width: 300px;
                height: 200px;
                margin-bottom: 20px;
                font-size: 14px;
                padding: 10px;

                @include mobile{
                    width: 200px;
                    height: 200px;
                  }
            }
            button{
                width: 150px;height: 30px;
                color: #fff;
                border: none;
                background-color: $mainColor;
                border-radius: 10px;
                cursor: pointer;
                font-weight: 600;

                &:focus{
                    outline: none;
                }
            }
            span{
                padding-top: 10px;
                color: #047c22;
            }
        }

    }
}