@import '../../global.scss';

.menu{
    width: 300px;
    height: 100vh;
    background-color: rgba(0,0,0,0);
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;

    &.active{
        right: 0;
        background-color: $mainColor;
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        color: #fff;
        width: 60%;
    }

    li{
        margin-bottom: 15px;
        a{
            font-size: inherit;
            color:#fff;
            text-decoration: none;
        }

        &:hover{
            font-weight: 500;
        }
    }
}
